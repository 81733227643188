import axios from "axios"
import { CSSProperties, useState } from "react"
import { Col, Container, Image, Row, Spinner } from "react-bootstrap"
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs"
import { SongProvider } from "../../../models/SongResponse"
import { SongProviderVote } from "../../../models/SongVotesDetailResponse"
import { HTTPStatusCode, Response } from "../esef/SplitFireView"
import { Link, Navigate } from "react-router-dom"
import { UserContext } from "../../../lib/CurrentUserContext"
import { CurrentUser } from "../../../lib/db"
import YouTube, { Options } from "react-youtube"
import { useContext } from "react"

enum State {
    LOADING,
    LOADED,
    ERROR
}

interface Props {
    songProvider: SongProvider,
    votes: SongProviderVote[]
}

export default function SongVotesDetailView(props: Props) {
    return (
        <Container className="mb-5">
            <TitleView name={props.songProvider.name} />
            <UpDownVotesView votes={props.votes} providerId={props.songProvider.id} />
            <YoutubeView provider_id={props.songProvider.provider_id} />
        </Container>
    )
}

function TitleView(props: { name: string }) {
    return (
        <Row className="mb-5">
            <Col>
                <h1 className="title text-center">{`Upvotes "${props.name}" so we can jam together!`}</h1>
            </Col>
        </Row>
    )
}

interface UpDownVotesViewProps {
    providerId: string
    votes: SongProviderVote[]
}

export enum VoteType {
    UP = 'up',
    DOWN = 'down'
}

function UpDownVotesView(props: UpDownVotesViewProps) {

    interface VoteState {
        aggregate: number
        buttonUpStyle: CSSProperties
        buttonDownStyle: CSSProperties
        upVotes: SongProviderVote[]
        downVotes: SongProviderVote[]
    }

    const [state, setState] = useState(State.LOADED)
    const [goToLogin, setGoToLogin] = useState(false)
    const { user } = useContext(UserContext)

    const buttonStyle = (votes: SongProviderVote[], type: VoteType): CSSProperties => {
        if (!user) {
            return { cursor: "pointer" }
        }

        if (type === VoteType.UP) {
            if (shouldDisableButton(votes, VoteType.UP, user)) {
                return { pointerEvents: "none", opacity: "0.4" }
            } else {
                return { cursor: "pointer" }
            }
        } else {
            if (shouldDisableButton(votes, VoteType.DOWN, user)) {
                return { pointerEvents: "none", opacity: "0.4" }
            } else {
                return { cursor: "pointer" }
            }
        }
    }

    const calculateVotes = (votes: SongProviderVote[]): number => {
        if (votes.length === 0) {
            return 0
        }

        const up = votes.filter((x) => x.vote_type === VoteType.UP).length
        const down = votes.filter((x) => x.vote_type === VoteType.DOWN).length
        //console.log('upvotes', up)
        //console.log('downvotes', down)
        const votesAggregate = up - down
        return votesAggregate
    }
    const shouldDisableButton = (votes: SongProviderVote[], type: VoteType, user: CurrentUser) => {
        if (!user) {
            return false
        }
        const x = votes.filter((y) => {
            if (y.user_id === user.user.id && y.vote_type === type)
                return true
            return false
        })
        return x.length > 0
    }

    const [voteState, setVoteState] = useState<VoteState>({
        aggregate: calculateVotes(props.votes),
        buttonUpStyle: buttonStyle(props.votes, VoteType.UP),
        buttonDownStyle: buttonStyle(props.votes, VoteType.DOWN),
        upVotes: props.votes.filter((x) => x.vote_type === VoteType.UP),
        downVotes: props.votes.filter((x) => x.vote_type === VoteType.DOWN)
    })

    const vote = (type: VoteType) => {

        if (!user || !user.accessToken || user.accessToken.length <= 1) {
            setGoToLogin(true)
            return
        }

        setState(State.LOADING)
        axios
            .post(`/song-bridge/${props.providerId}/vote`, { vote_type: type, provider_id: props.providerId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': user.accessToken,
                    }
                })
            .then(res => {
                const response: Response = res.data
                //console.log(response.votes)
                if (response.code === HTTPStatusCode.OK) {
                    setVoteState({
                        aggregate: calculateVotes(response.votes),
                        buttonUpStyle: buttonStyle(response.votes, VoteType.UP),
                        buttonDownStyle: buttonStyle(response.votes, VoteType.DOWN),
                        upVotes: response.votes.filter((x) => x.vote_type === VoteType.UP),
                        downVotes: response.votes.filter((x) => x.vote_type === VoteType.DOWN)
                    })
                    setState(State.LOADED)
                } else {
                    console.log('error')
                    setState(State.ERROR)
                }
            })
            .catch(error => {
                console.log(error)
                setState(State.ERROR)
            })
    }

    if (goToLogin) {
        return <Navigate to={'/login'} />
    }

    if (state === State.LOADING) {
        return <Row className="mb-3" style={{ minHeight: 250 }}>
            <Col className="align-self-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Col>
        </Row>
    }

    return (
        <Row className="mb-3 mt-3" style={{ minHeight: 250 }}>
            <Col xs={5}>
                <VoterGravatarsViews
                    voters={voteState.upVotes}
                    type={VoteType.UP}
                />
            </Col>
            <Col xs={2}>
                <BsArrowUpCircleFill
                    size={40}
                    className="mb-3"
                    style={voteState.buttonUpStyle}
                    onClick={() => vote(VoteType.UP)}
                    color="green"
                />
                <h1 className="votes">{voteState.aggregate}</h1>
                <BsArrowDownCircleFill
                    size={40}
                    className="mt-3"
                    style={voteState.buttonDownStyle}
                    onClick={() => vote(VoteType.DOWN)}
                    color="green"
                />
            </Col>
            <Col xs={5} className="align-self-end">
                <VoterGravatarsViews
                    voters={voteState.downVotes}
                    type={VoteType.DOWN}
                />
            </Col>
        </Row>
    )
}

function VoterGravatarsViews(props: { voters: SongProviderVote[], type: VoteType }) {
    const className = props.type === VoteType.DOWN ? "justify-content-end" : "justify-content-start"
    return (
        <Container>
            <Row className={className}>
                {props.voters.map((x, i) => {
                    return <Col xs={2} key={i} >
                        <Link to={`/@${x.voter_username_or_id}`}>
                            <Image roundedCircle src={x.voter_gravatar} width={24} />
                        </Link>
                    </Col>
                })}
            </Row>
        </Container>
    )
}

function YoutubeView(props: { provider_id: string }) {

    const opts: Options = {
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            mute: 0,
            controls: 0,
            rel: 0,
            showinfo: 0
        },
    }

    return (
        <Row className="mt-5">
            <Col>
                <YouTube
                    videoId={props.provider_id}
                    opts={opts}
                />
            </Col>
        </Row>
    )
}