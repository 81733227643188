import { useEffect, useState } from "react"
import { SongProvider } from "../../../models/SongResponse"
import axios from "axios"
import { FormattedMessage } from "react-intl"
import { Col, Container, Image, Row, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

enum State {
    LOADING,
    LOADED,
    ERROR
}

export default function TopVotesView() {

    const [state, setState] = useState(State.LOADING)
    const [files, setFiles] = useState<SongProvider[]>([])

    useEffect(() => {
        setState(State.LOADING)
        axios.get(`/top-votes`)
            .then(res => {
                const files = res.data.audio_files;
                console.log(files)
                setFiles(files)
                setState(State.LOADED)
            })
            .catch(error => {
                console.log(error)
                setState(State.ERROR)
            })
    }, [])

    if (state === State.LOADING) {
        return <div>
            <p>
                <FormattedMessage id="home.loading"
                    defaultMessage="Loading top votes..."
                    description="Loading top votes message" />
            </p>
            <Spinner animation='grow' variant="danger"></Spinner>
        </div>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Top Votes</h1>
                </Col>
            </Row>
            <Container>
                {files.map((file, index) => {
                    return (
                        <Link to={`/split/${file.id}`} key={index}>
                            <Row key={index} className="mb-3">
                                <Col xs={{ span: 6, offset: 3 }}>
                                    <Col xs={9}>
                                        <p>{file.name}</p>
                                        <Image src={`https://img.youtube.com/vi/${file.provider_id}/mqdefault.jpg`} thumbnail />
                                    </Col>
                                </Col>
                            </Row>
                        </Link>
                    )
                })
                }
            </Container>
        </Container>
    )

}