import { Row, Col, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function LoadingView() {

    return (
        <Row>
            <Col className="mb-3 mt-3">
                <p>
                    <FormattedMessage id="player.loading"
                        defaultMessage="🤖 I'm doing my business..."
                        description="Loading message" />
                </p>
                <Spinner animation='grow' variant="danger"></Spinner>
            </Col>
        </Row>
    )
}