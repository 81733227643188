import { Result } from "./Result";

export enum Status {
    REQUESTED = "requested",             // Requested to process file.
    PENDING = "pending",               // Fresly uploaded file.
    PROCESSING = "processing",            // Sidekiq is doing its business.
    DONE = "done",                  // Done processing file.
    CANCELLED = "cancelled",             // Cancelled processing file.
    NO_RESULTS = "no_results",            // The file has been processed before but the result files have been deleted.
    YOUTUBE_CONVERTING = "youtube_converting"
}

export interface AudioFile {
    id: number,
    name: string,
    youtube_video_id: string,
    youtube_thumbnail: string,
    status: Status,
    progress: number,
    results: Result[]
}