import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Carousel, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { SongProvider } from "../../../models/SongResponse";

enum State {
    LOADING,
    LOADED,
    ERROR
}

export default function CarouselView() {

    const [state, setState] = useState(State.LOADING)
    const [files, setFiles] = useState<SongProvider[]>([])

    useEffect(() => {
        setState(State.LOADING)
        axios.get(`/carousel`)
            .then(res => {
                const files = res.data.audio_files;
                console.log(files)
                setFiles(files)
                setState(State.LOADED)
            })
            .catch(error => {
                console.log(error)
                setState(State.ERROR)
            })
    }, [])

    if (state === State.LOADING) {
        return <div>
            <p>
                <FormattedMessage id="home.loading"
                    defaultMessage="Loading SplitFire AI..."
                    description="Loading message" />
            </p>
            <Spinner animation='grow' variant="danger"></Spinner>
        </div>
    }

    if (state === State.ERROR) {
        return <div>
            <p>
                <FormattedMessage id="home.error"
                    defaultMessage="Something wrong..."
                    description="Loading message" />
            </p>
        </div>
    }

    if (files.length === 0) {
        return <div>
            No content found
        </div>
    }

    return (
        <div>
            <Carousel>
                {files.slice(0, 5).map(item => (
                    <Carousel.Item key={item.id} style={{ padding: '20px' }}>
                        <img
                            className="d-block w-100"
                            src={item.image_url}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <Link to={{ pathname: `/split/${item.id}` }}>
                                <Button variant="dark">{item.name}</Button>
                            </Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}