import Dexie, { Table } from 'dexie';
import { Mode } from '../components/player/models/Mode';
import User from '../models/user';

export interface AudioFiles {
  id?: number;
  audioFileId: string,
  type: Mode,
  file: Blob
}

export enum CurrentUserType {
  MAIN, GUEST
}

export interface CurrentUser {
  accessToken: string
  type: CurrentUserType
  mode: Mode
  user: User
}

export class SplitfireDB extends Dexie {

  audioFiles!: Table<AudioFiles>;
  currentUser!: Table<CurrentUser>;



  constructor() {
    super('splitfireDB');
    this.version(6).stores({
      audioFiles: '++id, [audioFileId+type]', // Primary key and indexed props
      currentUser: '++id, [userId+type], type'
    });
  }

  async getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      db.currentUser.get({ type: CurrentUserType.MAIN }).then(res => {
        if (!res) {
          return reject("Not login")
        }
        resolve(res.accessToken)
      }).catch(error => {
        reject("Error when reading database")
      })
    })
  }
}

export const db = new SplitfireDB();