import { useEffect } from "react"
import { Spinner } from "react-bootstrap"

interface LogoutProps {
    didLogout: () => void
}

export default function Logout(props: LogoutProps) {

    useEffect(() => {
        props.didLogout()
    })

    return (
        <div>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}